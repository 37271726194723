import React, {
  useContext,
  useEffect,
  useMemo,
  useState,
  useRef,
  useCallback,
} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import useApi from '../hooks/useApi';
import { AuthContext } from '../index';
import { MenuItemBackEndInterface } from '../interfaces/MenuItem.types';
import { ProjectType } from '../interfaces/Project.types';
import { CanCascadeStatus } from '../interfaces/Cascade.types';

export type AppDataType = {
  settings: Record<string, string>;
  isAdmin: boolean | null;
  isEvaluator: boolean | null;
  isDeveloper: boolean | null;
  hasConsentTerms: boolean | null;
  isRegistered: boolean | null;
  nickname: string;
  teamId: number;
  profileId: number;
  regionId: number;
  profilePicture: string;
  canCompete: boolean | null;
  cannotCompeteReason: string | null;
  mainMenuItems: Array<MenuItemBackEndInterface>;
  projectId: number;
  projects: Array<ProjectType>;
  appStage: string;
  canCascadeStatus: null | CanCascadeStatus;
};

const appDataDefault = {
  settings: {},
  isAdmin: null,
  isEvaluator: null,
  isDeveloper: null,
  hasConsentTerms: null,
  isRegistered: null,
  nickname: '',
  teamId: 0,
  profileId: 0,
  regionId: 0,
  profilePicture: '',
  canCompete: null,
  cannotCompeteReason: null,
  mainMenuItems: [],
  projectId: 0,
  projects: [],
  appStage: '',
  canCascadeStatus: null,
};

export type HeaderIconButtonType = {
  icon: (props: React.SVGProps<SVGSVGElement>) => JSX.Element;
  onClickHandler?: (e: React.MouseEvent<HTMLButtonElement>) => void;
};

export type NotificationType = {
  message: string;
  detail?: string;
  type?: 'done' | 'error';
};

export type AppContextValueType = {
  appData: AppDataType;
  okToLoadApp: boolean;
  resetAppData: (appData: AppDataType) => void;
  headerTitle: string;
  updateHeaderTitle: (value?: string) => void;
  headerIconButton: null | HeaderIconButtonType;
  updateHeaderIconButton: (value?: HeaderIconButtonType) => void;
  resetHeader: () => void;
  isThisMyTeam: (teamId: number) => boolean | null;
  isThisMyProfile: (profileId: number) => boolean | null;
  isTeamMember: boolean;
  saveInProgressOpen: boolean;
  updateSaveInProgressOpen: (title?: string | null) => void;
  closeSaveInProgress: (show: false) => void;
  saveInProgressTitle: string;
  notificationOpen: boolean;
  updateNotificationOpen: (
    message?: string,
    detail?: string | undefined,
    type?: 'done' | 'error'
  ) => void;
  notification: NotificationType;
  notifyError: (message: string, detail: string | undefined) => void;
  isMobile: null | boolean;
  isProd: boolean;
  switchProject: (projectId: number) => Promise<void>;
  currentProject: null | ProjectType;
};

export const AppContext = React.createContext<AppContextValueType>({
  appData: appDataDefault,
  okToLoadApp: false,
  resetAppData: () => undefined,
  headerTitle: '',
  updateHeaderTitle: () => undefined,
  headerIconButton: null,
  updateHeaderIconButton: () => undefined,
  resetHeader: () => undefined,
  isThisMyTeam: (): boolean | null => null,
  isThisMyProfile: (): boolean | null => null,
  isTeamMember: false,
  saveInProgressOpen: false,
  updateSaveInProgressOpen: () => undefined,
  closeSaveInProgress: () => undefined,
  saveInProgressTitle: '',
  notificationOpen: false,
  updateNotificationOpen: () => undefined,
  notification: {
    message: '',
    detail: '',
    type: 'done',
  },
  notifyError: () => undefined,
  isMobile: null,
  isProd: true,
  switchProject: () => Promise.resolve(),
  currentProject: null,
});

function AppContextProvider({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element {
  const navigate = useNavigate();
  const api = useApi();
  const { config } = useContext(AuthContext);
  const location = useLocation();
  const prj_label = location.pathname.split('/')[1];

  const [okToLoadApp, setOkToLoadApp] = useState(false);
  const [currentProject, setCurrentProject] = useState<ProjectType | null>(
    null
  );
  const [appData, setAppData] = useState<AppDataType>(appDataDefault);
  const [headerTitle, setHeaderTitle] = useState<string>('');
  const [headerIconButton, setHeaderIconButton] =
    useState<null | HeaderIconButtonType>(null);
  const [saveInProgressOpen, setSaveInProgressOpen] = useState(false);
  const [saveInProgressTitle, setSaveInProgressTitle] = useState('');
  const [notificationOpen, setNotificationOpen] = useState(false);
  const [notification, setNotification] = useState<NotificationType>({
    message: '',
    detail: '',
    type: 'done',
  });

  const appDataHasBeedRequested = useRef(false);

  const isMobile = 'ontouchstart' in document.documentElement;
  const isProd = config.REACT_APP_ENVIRONMENT === 'prod';

  let notificationTimeout: ReturnType<typeof setTimeout> | null = null;

  const updateNotificationOpen = (
    message = '',
    detail = '',
    type: 'done' | 'error' = 'done'
  ): void => {
    setNotification({
      message,
      detail,
      type,
    });
    const showTime = type === 'error' ? 4000 : 2000;
    if (message.length) {
      setNotificationOpen(true);
      if (notificationTimeout !== null) {
        // clear active timeout
        clearTimeout(notificationTimeout);
      }
      // hide notification in 2 sec
      notificationTimeout = setTimeout(() => {
        setNotificationOpen(false);
      }, showTime);
    } else {
      setNotificationOpen(false);
    }
  };

  const notifyError = (
    message: string,
    detail: string | undefined = ''
  ): void => {
    updateNotificationOpen(message, detail, 'error');
  };
  const updateSaveInProgressOpen = (title: string | null = null): void => {
    setSaveInProgressTitle(title !== null ? title : '');
    if (title !== null) {
      setSaveInProgressOpen(true);
    } else {
      setSaveInProgressOpen(false);
    }
  };

  const closeSaveInProgress = (show: false): void => {
    setSaveInProgressOpen(show);
  };

  const updateHeaderTitle = (value?: string): void => {
    setHeaderTitle(value || '');
  };

  const updateHeaderIconButton = (value?: HeaderIconButtonType): void => {
    setHeaderIconButton(value || null);
  };

  const resetHeader = (): void => {
    updateHeaderTitle();
    updateHeaderIconButton();
  };

  const isThisMyTeam = (teamId: number): boolean => teamId === appData.teamId;

  const isThisMyProfile = (profileId: number): boolean =>
    profileId === appData.profileId;

  // function to reset appData from other components
  const resetAppData = (appDataToSet: AppDataType | undefined): void => {
    if (appDataToSet !== undefined) {
      // ok to reset appData
      setAppData(appDataToSet);
    }
  };

  const switchProject = useCallback(async (projectId: number) => {
    setOkToLoadApp(false);
    const response = await api.doRequest('', {
      method: 'GET',
      url: `${config.REACT_APP_API_BASE_URL}/project/switch-project/${projectId}`,
    });
    const fetchedAppData: AppDataType = response.data;
    // update appData
    setAppData(fetchedAppData);
    // set var that makes app render
    setOkToLoadApp(true);
  }, []);

  // function that fetches appData
  const fetchAppData: (mode: string) => Promise<void> = useCallback(
    async (mode) => {
      const params = new URLSearchParams([['mode', mode || '']]);
      if (prj_label) {
        params.append('prj_label', prj_label);
      }
      const response = await api.doRequest('', {
        method: 'GET',
        url: `${config.REACT_APP_API_BASE_URL}/authentication/app-data`,
        params,
      });
      const fetchedAppData: AppDataType = response.data;
      // update appData
      setAppData(fetchedAppData);

      if (location.pathname === '/') {
        // Take the current project label
        const currentProjectLabel =
          fetchedAppData.projects.find(
            ({ prj_id }) => prj_id === fetchedAppData.projectId
          )?.prj_label || 'we-know';
        // Navigate to the current project label
        navigate(`${currentProjectLabel}/`, { replace: true });
      }

      // set var that makes app render
      setOkToLoadApp(true);

      if (!fetchedAppData.isRegistered) {
        // LF-02) send non-logged-in user to login page
        navigate('/login', { replace: true });
      } else if (!fetchedAppData.hasConsentTerms) {
        // LF-03) send user to the login page for consent of new terms only
        navigate('/login', {
          replace: true,
          state: { mode: 'consentTermsOnly' },
        });
      }
    },
    []
  );

  useEffect(() => {
    const project =
      appData.projects.find(({ prj_id }) => prj_id === appData.projectId) ||
      null;
    setCurrentProject(project);
  }, [appData]);

  // on mount effect
  useEffect(() => {
    if (!appDataHasBeedRequested.current) {
      // LF-01) initial ensurance of app specific appData
      fetchAppData('init');
      appDataHasBeedRequested.current = true;
    }
  }, []);

  const isTeamMember = useMemo(() => appData?.teamId !== 0, [appData]);

  const contextValue: AppContextValueType = useMemo(
    () => ({
      appData,
      okToLoadApp,
      resetAppData,
      headerTitle,
      updateHeaderTitle,
      headerIconButton,
      updateHeaderIconButton,
      resetHeader,
      isThisMyTeam,
      isThisMyProfile,
      isTeamMember,
      saveInProgressOpen,
      updateSaveInProgressOpen,
      closeSaveInProgress,
      saveInProgressTitle,
      notificationOpen,
      updateNotificationOpen,
      notification,
      notifyError,
      isMobile,
      isProd,
      currentProject,
      switchProject,
    }),
    [
      appData,
      okToLoadApp,
      headerTitle,
      headerIconButton,
      saveInProgressOpen,
      saveInProgressTitle,
      notificationOpen,
      notification,
      isMobile,
      isProd,
      isTeamMember,
      currentProject,
    ]
  );

  return (
    <AppContext.Provider value={contextValue}>{children}</AppContext.Provider>
  );
}

export default AppContextProvider;
